import React, {Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {callRegisterForNot, deleteP5, fetchP5, fixThroughTeeTime, postP5, useInterval} from "../Utils";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Box, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import StandingsTable from './StandingsTable';
import LeaderboardTable from "./LeaderboardTable";
import SmackboardTable from "../components/SmackboardTable";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import purify from "dompurify";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckIcon from '@mui/icons-material/Check';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import {grey, green} from '@mui/material/colors';
import Button from "@mui/material/Button";
import Footer from "../components/Footer";
import TabPanel from "../components/TabPanel";

const inProgressRefreshInterval = 90;
const notInProgressRefreshInterval = 60 * 10;
const emptyRound = Array(18).fill(null);
class GolferObject {
    constructor(id, name) {
        this.Name = name;
        this.Id = id;
        this.Scores = [[...emptyRound], [...emptyRound], [...emptyRound], [...emptyRound]];
        this.RoundScores = [0, 0, 0, 0];
        this.course_ids = ["0", "0", "0", "0"];
        this.TotalScore = 0;
        this.Points = 0;
        this.Num_Picked = 1;
        this.Picked = false;
        this.eagles = 0;
        this.birdies = 0;
        this.bogies = 0;
        this.Cut = 0;
        this.holes_remaining = 0;
        this.Group_Num = 0;
        this.Shot_Details = [null,null,null,null];
        this.Show_Hole = 0; // zero based, tab control hole number, course hole number.  Not players nth hole played (if they started on 10)
        this.Show_PBP = false;
    }
}

class CourseObject {
    constructor(course) {
        this.id = course.course_id;
        this.par = course.Par;
        this.name = course.Name;
        this.code = course.code;
        this.holes = []; // par value for each hole
        this.scoring_level = course.scoring_level;
        for (let i = 0; i < 18; i++) {
            const key = "Hole" + (i+1);
            this.holes[i] = course[key];
        }
    }
}

function Golf(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [tournamentId, setTournamentId] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [refreshIn, setRefreshIn] = useState(inProgressRefreshInterval);
    const [refreshInterval, setRefreshInterval] = useState(inProgressRefreshInterval);
    const [fetchUrl, setFetchUrl] = useState("/api/v1/golf/pagedata");
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [entriesData, setEntriesData] = useState([]);
    const [configData, setConfigData] = useState({});
    const [smackData, setSmackData] = useState([]);
    const [standingsData, setStandingsData] = useState([]);
    const [pickedGolfers, setPickedGolfers] = useState({});
    const [watchedGolfers, setWatchedGolfers] = useState([]);
    const [myPicks, setMyPicks] = useState([]);
    const [coursesData, setCoursesData] = useState({});
    const [pick1PicksData, setPick1PicksData] = useState({});
    const [sendNotifications, setSendNotifications] = useState(false);
    const [lastShotNotifications, setLastShotNotifications] = useState({});
    const [smackPostEnabled, setSmackPostEnabled] = useState(true);

    // Non-changing data props: courses, picks, pick1picks, tournaments

    function entriesSortFunction(a,b) {
        const aPoints = a.Points - a.side_action_points;
        const bPoints = b.Points - b.side_action_points;
        if (a.Score !== b.Score) {
            return a.Score - b.Score;
        } else if (aPoints !== bPoints) {
            return bPoints - aPoints;
        } else {
            return a.EntryName.localeCompare(b.EntryName);
        }
    }
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'golf', props)
            .then(data => {
                const config = props.getState().golf.data.config.data[0];
                if (config.status === "IN_PROGRESS") {
                    setRefreshInterval(inProgressRefreshInterval);
                }
                else {
                    setRefreshInterval(notInProgressRefreshInterval);
                }

                setConfigData(config);
                setSmackData(props.getState().golf.data.smack.data);
                setStandingsData(props.getState().golf.data.standings.data);
                setCurrentTab(config.current_round - 1);
                
                if (data.__delta) {
                    const url = new URL(data.__delta);
                    // For the very first refresh (happens right after initial load) get all scores sine 1970.  Server will filter
                    // to the current round (first fetch for the init will get all rounds but without shot details).  So starting here
                    // we'll all get shot details for the current round.  Then after this first refresh call we'll just get updated
                    // round scores including shot details.
                    if (!fetchUrl.includes("?")) {
                        url.searchParams.set("sd", "all");
                    }
                    setFetchUrl(url.pathname + url.search);
                }

                if (isLoading && entriesData.length === 0) {
                    if (tournamentId === 0) {
                        setTournamentId(config.current_tournament);
                    }
                    processPicks(props.getState().golf.data.picks.data, config);
                    processCourses(props.getState().golf.data.courses.data);
                    processLeaderboard(props.getState().golf.data.leaderboard.data)
                    processExpandedCookie();
                    processWatchedGolfersCookie(tournamentId || config.current_tournament, props.getState().golf.data.leaderboard.data);
                    processPick1Picks(props.getState().golf.data.pick1picks.data);
                    if (config.is_admin) {
                        if ('Notification' in window) {
                            if (Notification.permission === 'default') {
                                Notification.requestPermission().then(function (result) {
                                    setSendNotifications(result === 'granted');
                                });
                            } else if (Notification.permission === 'granted') {
                                setSendNotifications(true);
                            }
                        }
                    }
                    setIsLoading(false);
                }

                processNotifications(props.getState().golf.data.leaderboard.data);
                setLeaderboardData(props.getState().golf.data.leaderboard.data);
                processStandings(props.getState().golf.data.standings.data);
                processScores(props.getState().golf.data.scores.data);
            });
    };

    useInterval(() => {
        if (refreshInterval) {
            if (refreshIn === 0 || window.p5MobileRefreshNow) {
                fetchData();
                setRefreshIn(refreshInterval);
                if (window.p5MobileRefreshNow) {
                    callRegisterForNot(props.getState().userName, props.getState().password);
                    delete window['p5MobileRefreshNow'];
                }
            }
            else {
                setRefreshIn(refreshIn - 1);
            }
        }
    }, 1000);
    
    useEffect(() => {
        document.title = "Pick 5 Golf";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, [tournamentId]);

    function processNotifications(newLeaderboardData) {
        if (sendNotifications) {
            const notificationToSend = watchedGolfers.reduce((text, golferId) => {
                const newRow = newLeaderboardData.filter((row) => row.Golfer_ID === golferId)[0];
                if (!newRow) {
                    return text;
                }
                const newShot = newRow.last_shot && newRow.last_shot !== "" ? newRow.last_shot : fixThroughTeeTime(newRow.Through);
                if (lastShotNotifications[golferId] !== newShot) {
                    lastShotNotifications[golferId] = newShot;
                    return text + (text !== '' ? '\n' : '') + newRow.Name.substr(newRow.Name.indexOf(' ') + 1) + ': ' + newShot;
                }
                else {
                    return text;
                }
            }, '');
            if (notificationToSend !== '') {
                new Notification(notificationToSend);
            }
            setLastShotNotifications(lastShotNotifications);
        }
    }
    
    function processScores(scoresData) {

        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfer.birdies = 0;
            golfer.eagles = 0;
            golfer.bogies = 0;
        }

        // Update each golfer object
        scoresData.forEach((scoreRow) => {
            if (pickedGolfers[scoreRow.golfer_ID]) {
                processScore(pickedGolfers[scoreRow.golfer_ID], scoreRow);
            }
        });

        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfer.TotalScore = golfer.RoundScores[0] +
                golfer.RoundScores[1] +
                golfer.RoundScores[2] +
                golfer.RoundScores[3];
        }

        // Update all entries with the new scores
        entriesData.forEach((entry) => {
            updateEntryScores(entry, 0);
            updateEntryScores(entry, 1);
            updateEntryScores(entry, 2);
            updateEntryScores(entry, 3);
            entry.TotalScore = entry.RoundScores[0] +
                entry.RoundScores[1] +
                entry.RoundScores[2] +
                entry.RoundScores[3];
        });
        
        entriesData.sort(entriesSortFunction);
    }

    function notNan(score, def) {
        return (score === null || isNaN(score) ? def : score);
    }

    function isNaNOrNull(score) {
        return isNaN(score) || (score === null);
    }
    
    // best ball minimum
    function bbMin(s1, s2, s3, s4) {
        if (isNaNOrNull(s1) && isNaNOrNull(s2) && isNaNOrNull(s3) && isNaNOrNull(s4)) {
            return s1;
        }
        return Math.min(notNan(s1, 99), notNan(s2, 99), notNan(s3, 99), notNan(s4, 99));
    }

    function opportunityGone(golfer, round, hole) {
        if (golfer.holes_remaining === 0) {
            return 1;
        }
        else if (golfer.course_ids[round] === '0') {
            return 1;
        }
        else if (isNaN(golfer.Scores[round][hole]) || golfer.Scores[round][hole] === null) {
            // haven't played it yet
            return 0;
        }
        else { // if (golfer.Scores[round][hole] < 0) {  // maybe anything not NaN means opportunity lost...
            return 1;
        }
    }
    
    function updateEntryScores(entry, roundNum) {
        let totalScore = 0;
        entry.GolferBirdieOpportunities[0][roundNum] = 18;
        entry.GolferBirdieOpportunities[1][roundNum] = 18;
        entry.GolferBirdieOpportunities[2][roundNum] = 18;
        entry.GolferBirdieOpportunities[3][roundNum] = 18;
        for (let i = 0; i < 18; i++) {
            entry.BestBall[roundNum][i] = bbMin(pickedGolfers[entry.Pick1].Scores[roundNum][i],
                pickedGolfers[entry.Pick2].Scores[roundNum][i],
                pickedGolfers[entry.Pick3].Scores[roundNum][i],
                pickedGolfers[entry.Pick4].Scores[roundNum][i]);
            const holeScore = notNan(entry.BestBall[roundNum][i], 0);
            totalScore = totalScore + holeScore;
            if (holeScore >= 0) {
                entry.GolferBirdieOpportunities[0][roundNum] -= opportunityGone(pickedGolfers[entry.Pick1], roundNum, i);
                entry.GolferBirdieOpportunities[1][roundNum] -= opportunityGone(pickedGolfers[entry.Pick2], roundNum, i);
                entry.GolferBirdieOpportunities[2][roundNum] -= opportunityGone(pickedGolfers[entry.Pick3], roundNum, i);
                entry.GolferBirdieOpportunities[3][roundNum] -= opportunityGone(pickedGolfers[entry.Pick4], roundNum, i);
            }
            else {
                entry.GolferBirdieOpportunities[0][roundNum]--;
                entry.GolferBirdieOpportunities[1][roundNum]--;
                entry.GolferBirdieOpportunities[2][roundNum]--;
                entry.GolferBirdieOpportunities[3][roundNum]--;
            }
        }
        entry.RoundScores[roundNum] = totalScore;
        entry.BirdieOpportunities[roundNum] = entry.GolferBirdieOpportunities[0][roundNum] +
            entry.GolferBirdieOpportunities[1][roundNum] +
            entry.GolferBirdieOpportunities[2][roundNum] +
            entry.GolferBirdieOpportunities[3][roundNum];
    }
    
    function processScore(golfer, scoreRow) {
        for (let i = 1; i < 19; i++) {
            golfer.Scores[scoreRow.round_number - 1][i-1] = scoreRow['Hole'+i];
        }
        golfer.RoundScores[scoreRow.round_number - 1] = 0;
        golfer.holes_remaining = scoreRow.holes_remaining;
        golfer.course_ids[scoreRow.round_number - 1] = scoreRow.course_id;
        for (let i = 0; i < 18; i++) {
            golfer.RoundScores[scoreRow.round_number - 1] += (golfer.Scores[scoreRow.round_number - 1][i] === undefined ? 0 : golfer.Scores[scoreRow.round_number - 1][i]);
        }
        
        golfer.birdies += scoreRow.birdies;
        golfer.eagles += scoreRow.eagles;
        golfer.bogies += scoreRow.bogies;
        golfer.Shot_Details[scoreRow.round_number - 1] = JSON.parse(scoreRow.shot_details);
        if (scoreRow.round_number === configData.current_round && golfer.Shot_Details[scoreRow.round_number - 1] !== null) {
            golfer.Show_Hole = findCurrentHole(golfer.Shot_Details[scoreRow.round_number - 1]);
        }
    }
    
    function processCourses(courses) {
        courses.forEach((course) => {
            coursesData[course.course_id] = new CourseObject(course);
        });
        setCoursesData({...coursesData});
    }
    
    function processLeaderboard(leaderboard) {
        // mark each picked golfer on the leaderboard
        leaderboard
            .filter((golfer) => pickedGolfers[golfer.Golfer_ID] !== undefined)
            .forEach((golfer) => {
                golfer.myPick = myPicks.includes(golfer.Golfer_ID);
                golfer.picked = true;
            });
    }
    
    function processStandings(standings) {
        // Update any entry properties that may have changed
        const entries = entriesData;
        standings
            .filter((row) => row.Tournament_ID !== 0)
            .forEach((row) => {
                entries
                    .filter((entry) => entry.EntryName === row.EntryName)
                    .forEach((entry) => {
                        entry.Points = Number(row.Points);
                        entry.Score = Number(row.Score);
                        entry.golfers_making_cut = Number(row.golfers_making_cut);
                        entry.side_action = row.side_action;
                        entry.side_action_score = Number(row.side_action_score);
                        entry.holes_remaining = Number(row.holes_remaining);
                        entry.side_action_points = Number(row.side_action_points);
                    });
            });
        setEntriesData(entries.sort(entriesSortFunction));
    }
    
    // Create a simple mapping of golferId and owner name
    function processPick1Picks(picks) {
        let pickObject = {};
        picks.forEach((pick) => {
            pickObject[pick.pick] = pick.entry_name
        });
        setPick1PicksData(pickObject);
    }
    
    function processPicks(picks, config) {
        const entries = entriesData;
        picks.forEach((pickRow) => {
            entries.push(
                {
                    ...pickRow,
                    'Expanded': false,
                    'RoundScores': [0, 0, 0, 0],
                    'BirdieOpportunities': [0,0,0,0],
                    'GolferBirdieOpportunities': [[], [], [], []],
                    'TotalScore': 0,
                    'BestBall': [[], [], [], []],
                }
            );
            if (!pickedGolfers[pickRow.Pick1]) {
                pickedGolfers[pickRow.Pick1] = new GolferObject(pickRow.Pick1, pickRow.Pick1Name);
            } else {
                pickedGolfers[pickRow.Pick1].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick2]) {
                pickedGolfers[pickRow.Pick2] = new GolferObject(pickRow.Pick2, pickRow.Pick2Name);
            } else {
                pickedGolfers[pickRow.Pick2].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick3]) {
                pickedGolfers[pickRow.Pick3] = new GolferObject(pickRow.Pick3, pickRow.Pick3Name);
            } else {
                pickedGolfers[pickRow.Pick3].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick4]) {
                pickedGolfers[pickRow.Pick4] = new GolferObject(pickRow.Pick4, pickRow.Pick4Name);
            } else {
                pickedGolfers[pickRow.Pick4].Num_Picked++;
            }
            if (config.EntryName === pickRow.EntryName) {
                //myEntry = pickRow;
                myPicks.push(pickRow.Pick1, pickRow.Pick2, pickRow.Pick3, pickRow.Pick4);
                setMyPicks([...myPicks]);
                pickedGolfers[pickRow.Pick1].Picked = true;
                pickedGolfers[pickRow.Pick2].Picked = true;
                pickedGolfers[pickRow.Pick3].Picked = true;
                pickedGolfers[pickRow.Pick4].Picked = true;
            }
        });
        setEntriesData(entries);
        setPickedGolfers({...pickedGolfers});
    }
    
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleTournamentChange = (event) => {
        const url = new URL(window.location.origin + fetchUrl);
        url.searchParams.set('tid', event.target.value);
        url.searchParams.delete('ts');
        delete(props.getState().golf);
        setFetchUrl(url.pathname + url.search);
        setTournamentId(event.target.value);
        setEntriesData([]);
        setLeaderboardData([]);
        setIsLoading(true);
    }

    function handleLeaderboardRowClick(row) {
        const golferId = Number(row.currentTarget.getAttribute('golferid'));
        const wIdx = watchedGolfers.indexOf(golferId);
        if (wIdx > -1) {
            watchedGolfers.splice(wIdx,1)
            setWatchedGolfers([...watchedGolfers]);
            saveWatchedGolfersCookie([...watchedGolfers], tournamentId);
        }
        else {
            const newWatchedGolfers = [...watchedGolfers, golferId];
            setWatchedGolfers(newWatchedGolfers);
            saveWatchedGolfersCookie(newWatchedGolfers, tournamentId);
        }
    }
    
    function handleSmackPostMessage(message, onSuccess) {
        setSmackPostEnabled(false);
        postP5(props.getState().userName, props.getState().password, '/api/v1/golf/smack', {
            'Message': message
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
                setSmackPostEnabled(true);
                onSuccess();
            });
    }
    
    function handleSmackDeleteMessage(messageId) {
        deleteP5(props.getState().userName, props.getState().password, '/api/v1/golf/smack?id=' + messageId)
            .then(response => response.json())
            .then(() => {
                setSmackData([...smackData.filter((message) => message.ID !== Number(messageId))]);
            });
    }
    
    function renderLoading() {
        return <div>Loading</div>;
    }

    /**
     *  Return the course hole that the player is on, or their final hole if done.
     * @param shotDetails
     * @returns {*}
     */
    function findCurrentHole(shotDetails) {
        let currentHole;
        let holeIndex;
        for (holeIndex = 0; holeIndex < 18; holeIndex++) {
            currentHole = shotDetails.holes[holeIndex].holeNumber;
            if (shotDetails.holes[holeIndex].score === "") {
                break;
            }
        }
        if (holeIndex === 18) {
            holeIndex = holeIndex - 1;
        }
        currentHole = currentHole - 1;
        if (shotDetails.holes[holeIndex].strokes.length < 1 && currentHole > 0) {
            currentHole = currentHole - 1;
        }
        return currentHole;
    }
    
    function renderShotDetailsContainer() {
        return (
        <div className="GolfShotDetailsContainer">
            {watchedGolfers.map((golferId) => {
                if (pickedGolfers[golferId] && pickedGolfers[golferId].Shot_Details) {
                    let pickedGolfer = pickedGolfers[golferId];
                    let roundNum = configData.current_round;
                    if (pickedGolfer.Shot_Details[roundNum-1]) {
                        let shotDetails = pickedGolfer.Shot_Details[roundNum-1];
                        // Show_Hole is the course hole number, may not be the nth hole the golfer is playing (if they started on 10).  So
                        // convert currentHole to the nth hole when passing in the shot details.
                        let currentHole = pickedGolfer.Show_Hole;
                        if (shotDetails.holes[0].holeNumber === 10) {
                            currentHole = (currentHole + 9) % 18;
                        }
                        let courseId = pickedGolfers[golferId].course_ids[roundNum-1];                        
                        if (coursesData[courseId].scoring_level === 'TOURCAST') {
                            // Maybe move this check outside the TOURCAST check if it applies to the non-tourcast display too
                            if (shotDetails.holes[currentHole].strokes.length < 1 && currentHole > 0) {
                                currentHole = currentHole - 1;
                            }
                            return renderShotDetails(pickedGolfers[golferId], shotDetails.holes[currentHole], courseId);
                        }
                        else {
                            // TODO: display shot details without the hole picture or nice graphics
                            return '';
                        }
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return '';
                }
            })}
        </div>
        );
    }

    function renderGolfData() {
        return (
            <div className="GolfDataContainer">
                <div>
                    <Box className="GolfScoresBox">
                        <div>
                            {renderHeaderBar()}
                            {renderScoreGrid()}
                        </div>
                        <div className="GolfStatsContainer">
                            {renderWatchedGolfers()}
                            {renderGolferStats()}
                            {configData.pick1golf_entry_id && renderPick1Picks()}
                        </div>
                    </Box>
                    {renderStandingsGrid()}
                </div>
                {renderShotDetailsContainer()}
            </div>
        )
    }
    
    function renderStandingsGrid() {
        return (<div className="GolfStandingsGridContainer">
            {renderStandings()}
            {renderLeaderboard()}
            <SmackboardTable
                smackboardData={smackData}
                myEntryName={configData.EntryName}
                onPostMessage={handleSmackPostMessage}
                onDeleteMessage={handleSmackDeleteMessage}
                postButtonEnabled={smackPostEnabled}
            />
            {renderSideAction()}
        </div>);
    }
    
    function renderStandings() {
        return StandingsTable(standingsData, configData);
    }

    function renderLeaderboard() {
        let projectedCut;
        if (configData.cut_round === configData.current_round) {
            projectedCut = configData.projected_cut;
        }
        return LeaderboardTable(leaderboardData, projectedCut, handleLeaderboardRowClick); 
    }

    function watchedRowClassName(row) {
        // If the golfer is one of "my" picks, try and color the row based on my best "best-ball" score for the hole he's on
        let currentHole
        if (row.last_shot) {
            let currentHoleIdx = row.last_shot.indexOf('Hole ');
            if (currentHoleIdx > -1) {
                currentHole = Number(row.last_shot.substring(5, 7));
            }
        }
        else if (row.Through) {
            const fixedThrough = row.Through.replace('*', '');
            if (!isNaN(fixedThrough)) {
                currentHole = Number(fixedThrough);
            }
        }

        if (currentHole && myPicks.includes(row.Golfer_ID)) {
            const myEntry = entriesData.filter((entry) => entry.EntryName === configData.EntryName).pop();
            if (myEntry && myEntry.BestBall && myEntry.BestBall[configData.current_round - 1]) {
                return golfScoreClass(myEntry.BestBall[configData.current_round - 1][currentHole - 1] || 0);
            }
        }

        return '';
    }
    
    function pickedGolfersArray() {
        let golfersArray = [];
        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfersArray.push(golfer);
        }
        return golfersArray.sort(pickedGolferSortFn);
    }
    
    function pickedGolferSortFn(a, b) {
        if (a.Num_Picked !== b.Num_Picked) {
            return b.Num_Picked - a.Num_Picked;
        }
        if (a.TotalScore !== b.TotalScore) {
            return a.TotalScore - b.TotalScore;
        }
    }
    
    function renderGolferStats() {
        return (
            <Paper className="GolfGolferStatsPaper">
                <TableContainer className="GolfGolferStatsTableContainer">
                    <Table size="small" stickyHeader className="GolfGolferStatsTable Pick5DataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Picked Count</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Birdies</TableCell>
                                <TableCell>Eagles</TableCell>
                                <TableCell>Bogies</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pickedGolfersArray().map((golfer) => {
                                return (
                                    <TableRow key={`GolferStatsRow${golfer.Id}`} className={myPicks.includes(golfer.Id) ? 'GolfLeaderboardRowMyPick' : ''}>
                                        <TableCell>{golfer.Name}</TableCell>
                                        <TableCell>{golfer.Num_Picked}</TableCell>
                                        <TableCell>{golfer.TotalScore}</TableCell>
                                        <TableCell>{golfer.birdies}</TableCell>
                                        <TableCell>{golfer.eagles}</TableCell>
                                        <TableCell>{golfer.bogies}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
    
    function handlePlayByPlayButtonClick(event) {
        let gid = event.currentTarget.dataset['golferId'];
        pickedGolfers[gid].Show_PBP = !pickedGolfers[gid].Show_PBP;
    }
    
    function renderPick1Picks() {
        return (
            <Fragment>
                <h4 className="GolfPick1PicksTableHeader">Pick1 Contest</h4>
                <Table size="small" className="Pick5DataTable GolfPick1PicksTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Through</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaderboardData.filter((row) => pick1PicksData.hasOwnProperty(row.Golfer_ID)).map((row) => {
                            return (
                                <TableRow key={`Pick1TableRow${row.Golfer_ID}`}>
                                    <TableCell>{`(${row.Position || ''}) ${row.Name}`}</TableCell>
                                    <TableCell>{row.Score}</TableCell>
                                    <TableCell>{fixThroughTeeTime(row.Through)}</TableCell>
                                    <TableCell>{pick1PicksData[row.Golfer_ID]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    
    function renderWatchedGolfers() {
        return (
            <Table size="small" className="GolfWatchedGolfersTable Pick5DataTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Shot</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {watchedGolfers.map((golferId) => 
                        leaderboardData
                            .filter((row) => row.Golfer_ID === golferId)
                            .map((row) => {
                                const lastShotString = row.last_shot && row.last_shot !== "" ? row.last_shot : fixThroughTeeTime(row.Through);
                                return <TableRow className={watchedRowClassName(row)} onClick={handleLeaderboardRowClick} golferid={row.Golfer_ID} key={`WatchedGolfersRow${row.Golfer_ID}`}>
                                    <TableCell>{`(${row.Position ? row.Position : ''}) ${row.Name}`}</TableCell>
                                    <TableCell>{lastShotString}</TableCell>
                                </TableRow>
                            }
                        )
                    )}
                </TableBody>
            </Table>
            );
    }

    function u(e, t) {
        if ("number" == typeof e)
            return e * (t ? 2 : 1);
        if (2 !== e.length)
            throw Error("invalid trail item size: ".concat(JSON.stringify(e)));
        let[l,r] = e;
        return t ? l : r
    }

    let yardMarker = {
        fillColor: "#FFFFFF",
        fontSize: 36,
        height: 56,
        radius: 32,
        strokeWidth: 8,
        textDx: 52,
        textDy: 40,
        textFontWeight: "600",
        width: 106
    };
    
    function renderStrokeDistance(stroke) {
        let yellow = "#F5A81C";
        let gold = "#F9CD7E";
        let black = "#000000";
        let white = "#FFFFFF";
        
        let {x: t, y: l, yardage: o, strokeColor: i} = stroke
            , c = yardMarker.width / 2
            , h = yardMarker.height / 2;
        return (
            <Fragment>
                <rect x={t-c} y={l-h} width={yardMarker.width} height={yardMarker.height} rx={yardMarker.radius}
                      fill={i} stroke={i} strokeWidth={yardMarker.strokeWidth}/>
                <text textAnchor="middle" x={t-c} y={l-h} dx={yardMarker.textDx} dy={yardMarker.textDy}
                      fontWeight={yardMarker.textFontWeight} fontSize={yardMarker.fontSize} fill={i === yellow || i === gold ? black : white}>{o}</text>
            </Fragment>
        );
    }
    
    function holeScoreText(shotDetails, stroke) {
        if (stroke.strokeNumber > shotDetails.par + 1) {
            return 'dbl bogey +';
        }
        else if (stroke.strokeNumber > shotDetails.par) {
            return 'bogey';
        }
        else if (stroke.strokeNumber === shotDetails.par) {
            return 'par';
        }
        else if (stroke.strokeNumber === shotDetails.par - 1) {
            return 'birdie';
        }
        else if (stroke.strokeNumber === 1) {
            return 'ace';
        }
        else if (stroke.strokeNumber === shotDetails.par - 2) {
            return 'eagle';
        }
        else if (stroke.strokeNumber === shotDetails.par - 3) {
            return 'albatross';
        }
        else {
            return 'unknown';
        }
    }
    
    const handleHoleDetailsChange = (event, newValue) => {
        let gid = event.target.dataset['golferId'];
        pickedGolfers[gid].Show_Hole = newValue;
    };

    function renderHoleSelector(golferId) {
        return (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={pickedGolfers[golferId].Show_Hole} onChange={handleHoleDetailsChange} variant="fullWidth">
                    {[...Array(18).keys()].map((hole) => {
                        return <Tab sx={{minWidth: '1px'}} key={golferId+'shotDetailsHoleSelector'+hole} label={hole+1} data-golfer-id={golferId}/>
                    })}
                </Tabs>
            </Box>
        );
    }

    function renderShotDetails(pickedGolfer, shotDetails, courseId) {
        let containerHeight = 415.711;
        let containerWidth = 970;
        let svgHeight = 1314;
        let svgWidth = 3072;
        let pinX = (shotDetails.pinOverview.leftToRightCoords.enhancedX * svgWidth) - 10,
            pinY = (shotDetails.pinOverview.leftToRightCoords.enhancedY * svgHeight) - 67.2 + 2;
        //let imgsrc = "https://pga-tour-res.cloudinary.com/tourcastPickle/" + shotDetails.holePickleLeftToRightAsset.imagePath.split(",")[3].split("/")[1] + "_land.png";
        let imgsrc = "https://www.pickfive.org/images/golf/holes/" + configData.current_year + "/holes_" + configData.current_year + "_" +  configData.tour_code.toLowerCase() + "_" + configData.Perm_Num + "_" + courseId + "_overhead_full_" + shotDetails.holeNumber + "_land.png";
        let currentStroke = (shotDetails.strokes.length === 0 ? null : shotDetails.strokes[shotDetails.strokes.length-1]);
        // ratio 2.333351775632591, 976 - (106 * ratio) / 2
        return (
            <div className="GolfShotDetailsContainerRow">
                <div className="GolfShotDetailsGolferName">{pickedGolfer.Name}</div>
                {renderHoleSelector(pickedGolfer.Id)}
                <div style={{width: `${containerWidth}px`, height: `${containerHeight}px`, position: "relative"}} className="GolfShotDetails">
                <div className="chakra-aspect-ratio css-v6e02t">
                    <img 
                        alt="Hole pickle" 
                        loading="lazy" 
                        decoding="async"
                        data-nimg="fill"
                        src={imgsrc}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            inset: '0',
                            objectFit: 'cover',
                            color: 'transparent'
                    }}/>
                    <div className="css-1w7rdu9">
                        <svg className="shot-trails" viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                             style={{position: 'absolute'}}>

                            {/* Draw shot lines */}
                            {shotDetails.strokes.map((stroke) => {
                                let fx = stroke.overview.leftToRightCoords.fromCoords.enhancedX * svgWidth,
                                    fy = stroke.overview.leftToRightCoords.fromCoords.enhancedY * svgHeight,
                                    tx = stroke.overview.leftToRightCoords.toCoords.enhancedX * svgWidth,
                                    ty = stroke.overview.leftToRightCoords.toCoords.enhancedY * svgHeight;
                                return (
                                    <path d={`M${fx} ${fy} L${tx} ${ty}`} stroke="#0084FF" strokeWidth="6"/>
                                )
                            })}
                            {/* Draw starting circle */}
                            {shotDetails.strokes.length ?
                                <circle cx={shotDetails.strokes[0].overview.leftToRightCoords.fromCoords.enhancedX * svgWidth}
                                        cy={shotDetails.strokes[0].overview.leftToRightCoords.fromCoords.enhancedY * svgHeight} r="18"
                                        stroke="white" fill="#ffffff"/> : ''
                            }
                            {/* Draw shot distances */}
                            {shotDetails.strokes.map((stroke) => {
                                if (stroke.markerText === '0' || Number(stroke.markerText) < 30) {
                                    return '';
                                }
                                let x = (((stroke.overview.leftToRightCoords.toCoords.enhancedX * svgWidth) -
                                    (stroke.overview.leftToRightCoords.fromCoords.enhancedX * svgWidth)) / 2) + (stroke.overview.leftToRightCoords.fromCoords.enhancedX * svgWidth) -
                                    (yardMarker.width / 2);
                                let y = (((stroke.overview.leftToRightCoords.toCoords.enhancedY * svgHeight) -
                                    (stroke.overview.leftToRightCoords.fromCoords.enhancedY * svgHeight)) / 2) + (stroke.overview.leftToRightCoords.fromCoords.enhancedY * svgHeight) -
                                    (yardMarker.height / 2);
                                return ( 
                                    <Fragment>
                                        <rect x={x} y={y} width="106" height="56"
                                              rx="32"
                                              fill="#0084FF" stroke="#0084FF" strokeWidth="8"/>
                                        <text textAnchor="middle" x={x} y={y} dx="52"
                                              dy="40"
                                              fontWeight="600" fontSize="36" fill="#FFFFFF">{stroke.markerText}
                                        </text>
                                    </Fragment>
                                )
                            })}
                            
                            {/* Draw pin on green svg */}
                            <svg x={pinX} y={pinY} width="40" height="67.2" viewBox="0 0 12 24" fill="none">
                                <line x1="1.5" y1="1" x2="1.5" y2="23" stroke="black" strokeOpacity="0.05"></line>
                                <path d="M3 1L6.75 6H10.5L12 12H4.5L3 10V7V1Z" fill="#FAE100"></path>
                                <path d="M3 1V10L4.5 12V10.5V7.5L6.75 6L3 1Z" fill="#BBA900"></path>
                                <path
                                    d="M5 22.5C5 23.3284 3.88071 24 2.5 24C1.11929 24 0 23.3284 0 22.5C0 21.6716 1.11929 21 2.5 21C3.88071 21 5 21.6716 5 22.5Z"
                                    fill="black" fillOpacity="0.5"></path>
                                <path d="M2 1H3V23H2V1Z" fill="white"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 10L2 1L3 1L3 10L2 10Z"
                                      fill="#7D7000"></path>
                                <path
                                    d="M3 0.5C3 0.776142 2.77614 1 2.5 1C2.22386 1 2 0.776142 2 0.5C2 0.223858 2.22386 0 2.5 0C2.77614 0 3 0.223858 3 0.5Z"
                                    fill="white"></path>
                            </svg>

                            {/* Draw shot count circles */}
                            {shotDetails.strokes.map((stroke) => {
                                let tx = stroke.overview.leftToRightCoords.toCoords.enhancedX * svgWidth,
                                    ty = stroke.overview.leftToRightCoords.toCoords.enhancedY * svgHeight;
                                return (
                                    <Fragment>
                                        {stroke.finalStroke ?
                                            <circle cx={tx} cy={ty} r="30" stroke="#FFFFFF" strokeWidth="4" fill="#0084FF"></circle> :
                                            <circle cx={tx} cy={ty} r="30" fill={stroke.strokeType === "PENALTY" ? "red" : "#0084FF"}></circle>
                                        }
                                        <text textAnchor="middle" x={tx} y={ty}
                                              dy=".35em"
                                              fontWeight="600" fontSize="40" fill="white">{stroke.strokeNumber}
                                        </text>
                                    </Fragment>
                                )
                            })}
                        </svg>
                    </div>
                </div>

                {/* Draw the hole information overlay */}
                <div className="GolfShotDetailsInfoOverlayContainer">
                    <div className="GolfShotDetailsInfoOverlay">
                        <span style={{fontSize: "58px"}} className="GolfShotDetailsInfoHoleRow">{shotDetails.holeNumber}</span>
                        <div style={{width: "16px", height: "16px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "13px", lineHeight:"14px"}} className="chakra-text GolfShotDetailsInfoHoleRow">PAR</span>
                        <div style={{width: "4px", height: "4px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "35px", lineHeight:"38px"}} className="chakra-text GolfShotDetailsInfoHoleRow">{shotDetails.par}</span>
                        <div style={{width: "24px", height: "24px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "13px", lineHeight:"14px"}} className="chakra-text GolfShotDetailsInfoHoleRow">YARDS</span>
                        <div style={{width: "4px", height: "4px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "35px", lineHeight:"38px"}} className="chakra-text GolfShotDetailsInfoHoleRow">{shotDetails.yardage}</span>
                        <div style={{width: "24px", height: "24px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "13px", lineHeight:"14px"}} className="chakra-text GolfShotDetailsInfoHoleRow">RANK</span>
                        <div style={{width: "4px", height: "4px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                        <span style={{fontSize: "35px", lineHeight:"38px"}} className="chakra-text GolfShotDetailsInfoHoleRow">{shotDetails.rank}</span>
                        <div style={{width: "24px", height: "24px"}} className="GolfShotDetailsInfoHoleRowSpacer"></div>
                    </div>


                    <div className={pickedGolfer.Show_PBP ? "GolfShotDetailsRightSidePanelOpen" : "GolfShotDetailsRightSidePanelClosed"}>
                        <div className="GolfShotDetailsRightSidePanelButtonContainer">
                            <button tabIndex="0" className="GolfShotDetailsRightSidePanelButton" onClick={handlePlayByPlayButtonClick} data-golfer-id={pickedGolfer.Id}>
                                <div className="GolfShotDetailsPlayByPlayButtonContainer">
                                    <span className="GolfShotDetailsPlayByPlayButtonText">Play By Play</span></div>
                            </button>
                        </div>
                    </div>

                    {/* Draw the play by play shot container */}
                    <div className={pickedGolfer.Show_PBP ? "GolfShotDetailsPlayByPlayContainerOpen" : "GolfShotDetailsPlayByPlayContainerClosed"}>
                        {pickedGolfer.Show_PBP ? (
                            <div className="GolfPBP-nsinwu">
                                <div className="GolfPBP-1ufrzn0">
                                    <h2 className="GolfPBP-1h0wur3">Play By Play</h2>
                                    <button type="button" className="chakra-button GolfPBP-18ffrip" aria-label="Close button" onClick={handlePlayByPlayButtonClick} data-golfer-id={pickedGolfer.Id}>
                                        <svg viewBox="0 0 13 12" focusable="false" className="chakra-icon GolfPBP-275bqz" aria-label="Close">
                                            <path fill="currentColor" fillRule="evenodd"
                                                  d="M6.5 4.94L4.202 2.64l-1.06 1.06L5.438 6 3.141 8.298l1.061 1.06L6.5 7.062l2.298 2.298 1.06-1.06L7.562 6l2.298-2.298-1.06-1.06L6.5 4.938z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="GolfPBP-1sabknn"/>
                                <div aria-orientation="horizontal" role="presentation" className="chakra-divider GolfPBP-2i83vv"/>
                                <div className="GolfPBP-1sabknn"/>
                                <div data-simplebar="init" className="dark-theme simplebar-scrollable-y GolfPBPSimpleBarContainer">
                                    <div className="simplebar-wrapper">
                                        <div className="simplebar-height-auto-observer-wrapper">
                                            <div className="simplebar-height-auto-observer"/>
                                        </div>
                                        <div className="simplebar-mask">
                                            <div className="simplebar-offset">
                                                <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content">
                                                    <div className="simplebar-content">
                                                        <div className="GolfPBP-pkn9ma">
                                                            {shotDetails.strokes.map((stroke) => 
                                                                <div className="GolfPBP-145umzp">
                                                                    <div className="GolfPBP-29rmbm">
                                                                        <div className="GolfPBP-1vysk0b"/>
                                                                        <div className="GolfPBP-1170h9y">
                                                                            <div className="GolfPBP-12lbfbz">
                                                                                <span className="chakra-text GolfPBP-1ph61kr">Shot {stroke.strokeNumber}</span>
                                                                                <span className="chakra-text GolfPBP-kmsc9k">{stroke.playByPlay}</span>
                                                                                {stroke.finalStroke ? (
                                                                                    <div className="GolfPBP-1xzhtrx">
                                                                                        <div className="GolfPBP-1u4uyif">
                                                                                            <span className="chakra-text GolfPBP-1s3yo9o">{holeScoreText(shotDetails, stroke)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="GolfPBP-1vysk0b"/>
                                                                        <div role="presentation"
                                                                             className="chakra-divider GolfPBP-s08p0u"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="GolfPBP-1qzcbpb"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="simplebar-placeholder GolfPBP-kjdeda"/>
                                    </div>
                                    {/*
                                    <div className="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                                        <div className="simplebar-scrollbar" style="width: 0px; display: none;"/>
                                    </div>
                                    <div className="simplebar-track simplebar-vertical" style="visibility: visible;">
                                        <div className="simplebar-scrollbar" style="height: 261px; transform: translate3d(0px, 0px, 0px); display: block;"/>
                                    </div>
                                    */}
                                </div>
                            </div>) : ''
                        }
                    </div>
                    
                    {currentStroke && !pickedGolfer.Show_PBP ? (
                        <div className="GolfShotDetailsBottomInfo">
                            <div className="GolfShotDetailsLastShotContainer">
                                <div className="GolfShotDetailsLastShotBox">
                                    <div className="GolfShotDetailsLastShotPill">
                                        <span className="GolfShotDetailsLastShotNumber">Shot {currentStroke.strokeNumber}</span>
                                        <span className="GolfShotDetailsLastShotText">{currentStroke.playByPlay}</span>
                                    </div>
                                </div>
                            </div>
                        </div>) : ''
                    }
                </div>
            </div>
            </div>
        );
    }

    function sideActionSort(a, b) {
        if (a.side_action_score !== b.side_action_score) {
            return a.side_action_score - b.side_action_score;
        }
        return a.EntryName.localeCompare(b.EntryName);
    }

    function handleOptInButtonClick() {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/golf/sideactionoptin')
            .then(() => fetchData());
    }

    function renderSideAction() {
        return (
            <div className="GolfStandingsGridItem">
                <h4 className="GolfSideActionTableHeader">Side Action</h4>
                <div className="GolfSideActionContainer">
                    {[4, 3, 2, 1].map((groupNum) => {
                        const groupEntries = standingsData.filter((row) => row.Tournament_ID === tournamentId && row.golfers_making_cut === groupNum);
                        if (groupEntries.length === 0) {
                            return '';
                        }
                        return (
                            <Table size="small" className="Pick5DataTable" key={'SideActionTableGroup' + groupNum}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                className={"GolfSideActionGroupNum GolfScoreRowEntryCutCount GolfScoreRowEntryCutCountColor" + groupNum}>{groupNum}</div>
                                        </TableCell>
                                        <TableCell>In</TableCell>
                                        <TableCell>Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groupEntries.sort(sideActionSort).map((entry) => {
                                        return (
                                            <TableRow key={'sideActionRow' + entry.ID}>
                                                <TableCell>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: purify.sanitize(entry.EntryName)}}/>
                                                </TableCell>
                                                <TableCell>
                                                    {!entry.side_action && entry.EntryName === configData.EntryName && configData.side_action_open ?
                                                        (
                                                            <Button size="small" className="SideActionOptInButton"
                                                                    onClick={handleOptInButtonClick}>Opt In</Button>
                                                        ) :
                                                        (
                                                            entry.side_action > 0 ?
                                                                <CheckIcon sx={{fill: green[500], fontSize: 14}}/> : ''
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell>{entry.side_action_score}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        );
                    })}
                </div>
            </div>
        );
    }

    function renderScoreGrid() {
        return (
            <div className="GolfScoreGridContainer">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
                        <Tab label="Round 1"/>
                        <Tab label="Round 2"/>
                        <Tab label="Round 3"/>
                        <Tab label="Round 4"/>
                    </Tabs>
                </Box>
                <TabPanel value={currentTab} index={0}>{renderScoreTab(1)}</TabPanel>
                <TabPanel value={currentTab} index={1}>{renderScoreTab(2)}</TabPanel>
                <TabPanel value={currentTab} index={2}>{renderScoreTab(3)}</TabPanel>
                <TabPanel value={currentTab} index={3}>{renderScoreTab(4)}</TabPanel>
            </div>
        );
    }
    
    function renderEntryNameCell(roundNum, entry) {
        return (
            <TableCell className="GolfScoreRowEntryNameCell">
                <div onClick={() => handleEntryExpandClick(entry)} className="GolfScoreRowEntryNameCellContainer">
                    {entry.Expanded ? <RemoveOutlinedIcon sx={{ color: grey[500] }} className="GolfScoreRowExpandIcon"/> : <AddBoxIcon sx={{ color: grey[500] }} className="GolfScoreRowExpandIcon"/>}
                    <div dangerouslySetInnerHTML={{ __html: purify.sanitize(entry.EntryName) }} className="GolfScoreRowName"/>
                    <div className={"GolfScoreRowEntryCutCount GolfScoreRowEntryCutCountColor"+entry.golfers_making_cut}>{entry.golfers_making_cut}</div>
                </div>
            </TableCell>
        );
    }
    
    function golfScoreClass(score, courseId, holeNum) {
        switch (score) {
            case null: return '';
            case 0:  return 'GolfColorPar';
            case 1:  return 'GolfColorBogey';
            case 2:  return 'GolfColorDoubleBogey';
            case -1: return 'GolfColorBirdie';
            case -2: {
                if (courseId && holeNum) {
                    return coursesData[courseId].holes[holeNum] === 3 ? 'GolfColorAce' : 'GolfColorEagle';
                }
                else {
                    return 'GolfColorEagle';
                }
            }
            case -3: return 'GolfColorDoubleEagle';
            default:
                return score > 0 ? 'GolfColorDoubleBogey' : 'GolfColorDoubleEagle';
        }
    }
    
    function renderScoreRow(roundNum, entryId, golferId, scores, roundScore, holesRemaining, birdieOpportunitiesRemaining, totalScore, courseId) {
        return (
            <Fragment>
                {scores.map((score,index) => 
                    <TableCell key={roundNum+'E'+entryId+'score'+index} className={golfScoreClass(score, courseId, index)}>
                        {score !== null ? score : ''}
                    </TableCell>)
                }
                <TableCell key={roundNum+'E'+entryId+'roundScore'+golferId}>{roundScore}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'holesRemaining'+golferId}>{holesRemaining}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'BOR'+golferId}>{birdieOpportunitiesRemaining}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'totalScore'+golferId}>{totalScore}</TableCell>
            </Fragment>
        );
    }
    
    function renderScoreTab(roundNum) {
        return (             
            <TableContainer component={Paper} className="GolfScoreTableContainer">
                <Table size="small" className="GolfScoreTable Pick5DataTable">
                    <TableHead>
                        <TableRow key={roundNum+'headerRow'}>
                            <TableCell key={roundNum+'emptyCell'}/>
                            {[...Array(18).keys()].map((hole) => {
                                return <TableCell key={roundNum+'headerHole'+hole}>{hole+1}</TableCell>
                            })}
                            <TableCell key={roundNum+'headerRound'}>Round</TableCell>
                            <TableCell key={roundNum+'headerHR'}>HR</TableCell>
                            <TableCell key={roundNum+'headerBOR'}>BOR</TableCell>
                            <TableCell key={roundNum+'headerTotal'} align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entriesData.map((entry) => {
                            return (
                                <Fragment key={entry.id+'fragment'}>
                                    <TableRow key={roundNum+'E'+entry.id+'bbRowTop'}>
                                        {renderEntryNameCell(roundNum, entry)}
                                        {!entry.Expanded && 
                                            renderScoreRow(roundNum, entry.id, 1, entry.BestBall[roundNum-1], entry.RoundScores[roundNum-1], entry.holes_remaining, entry.BirdieOpportunities[roundNum-1], entry.TotalScore)
                                        }
                                    </TableRow>
                                    {entry.Expanded && (<Fragment>
                                            {[...Array(4).keys()].map((index) => {
                                                const golfer = pickedGolfers[entry['Pick'+(index+1)]];
                                                let courseCode = '';
                                                if (Object.keys(coursesData).length > 1 && coursesData[golfer.course_ids[configData.current_round - 1]]) {
                                                    courseCode = ` (${coursesData[golfer.course_ids[configData.current_round - 1]].code})`;
                                                }
                                                return (
                                                    <TableRow key={roundNum+'E'+entry.id+'nameRow'+golfer.Id}>
                                                        <TableCell key={roundNum+'E'+entry.id+'golferNameCell'+golfer.Id}>{golfer.Name}{courseCode}</TableCell>
                                                        {renderScoreRow(roundNum, 
                                                            entry.id,
                                                            golfer.Id,
                                                            golfer.Scores[roundNum-1],
                                                            golfer.RoundScores[roundNum-1],
                                                            golfer.holes_remaining, 
                                                            entry.GolferBirdieOpportunities[index][roundNum-1],
                                                            golfer.TotalScore,
                                                            golfer.course_ids[roundNum-1]
                                                        )}
                                                    </TableRow>
                                                    )
                                                })
                                            }
                                            <TableRow key={roundNum+'E'+entry.id+'bbRowBottom'}>
                                                <TableCell key={roundNum+'E'+entry.id+'bbRowLabel'}>Best Ball</TableCell>
                                                {renderScoreRow(roundNum, entry.id, 2, entry.BestBall[roundNum-1], entry.RoundScores[roundNum-1], entry.holes_remaining, entry.BirdieOpportunities[roundNum-1], entry.TotalScore)}
                                            </TableRow>
                                        </Fragment>
                                    )}
                                </Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    
    function handleRefreshNowClick() {
        setRefreshIn(0);
    }
    
    function handleEntryExpandClick(entry) {
        const entries = entriesData;
        entries.filter((e) => e.id === entry.id).forEach((e) => e.Expanded = !e.Expanded);
        setEntriesData([...entries]);
        saveExpandedCookie();
    }

    function saveExpandedCookie() {
        const expanded = entriesData
            .filter((entry) => entry.Expanded)
            .reduce((expanded, entry) => expanded += entry.id + ',', '');
        localStorage.setItem("golf_expanded", expanded);
    }

    function processExpandedCookie() {
        const expandedCookie = localStorage.getItem("golf_expanded");
        if (expandedCookie) {
            const entryIds = expandedCookie.split(",");
            entryIds.forEach((id) =>
                entriesData
                    .filter((e) => e.id === Number(id))
                    .forEach((e) => e.Expanded = true)
            );
        }
    }

    function saveWatchedGolfersCookie(golfers, currentTournament) {
        if (golfers.length > 0) {
            let cookieVal = golfers.reduce((cookieStr, golferId) =>
                cookieStr ? cookieStr + ',' + golferId : golferId, '');
            localStorage.setItem('golf_watched_golfers', currentTournament + ":" + cookieVal);
        }
    }

    function processWatchedGolfersCookie(currentTournament, leaderboardData) {
        const watchedCookie = localStorage.getItem('golf_watched_golfers');
        if (watchedCookie) {
            const cookieBits = watchedCookie.split(':');
            if (cookieBits.length < 2 ) {
                setWatchedGolfers([...myPicks]);
                saveWatchedGolfersCookie(myPicks, currentTournament);
                return;
            }
            if (Number(cookieBits[0]) !== currentTournament) {
                setWatchedGolfers([...myPicks]);
                return;
            }
            const watchedIds = cookieBits[1].split(',');
            const newLastShotNotifications = lastShotNotifications;
            watchedIds.forEach((watchedId) =>
                leaderboardData
                    .filter((row) => row.Golfer_ID === Number(watchedId))
                    .forEach((row) => {
                        row.Watched = true;
                        // also setup the last notifications
                        newLastShotNotifications[row.Golfer_ID] = row.last_shot && row.last_shot !== "" ? row.last_shot : fixThroughTeeTime(row.Through);                            
                        })
            );
            setLastShotNotifications(newLastShotNotifications);
            setWatchedGolfers(watchedIds.map((id) => Number(id)));
        }
        else {
            setWatchedGolfers([...myPicks]);
            saveWatchedGolfersCookie(myPicks, currentTournament);
        }
    }
    
    function renderHeaderBar() {
        return <div className="GolfHeaderBar">
            <div className="GolfHeaderBarTournamentLabel">Tournament:</div> 
            <Select
                size="small"
                value={tournamentId}
                fullWidth
                onChange={handleTournamentChange}
                className="GolfHeaderBarTournamentSelect"
            >
                {props.getState().golf.data.tournaments.data.map(( tournament ) => {
                    return <MenuItem key={'tournament-menuitem-'+ tournament.ID} value={tournament.ID}>{tournament.name}</MenuItem>;
                })}
            </Select>
            <div className="GolfHeaderStatus">
                <div>Status: {configData.status}</div>
                {refreshInterval ? <div className="GolfRefreshIn" onClick={handleRefreshNowClick}>Refresh In: {refreshIn} seconds</div> : ''}
            </div>
        </div>;
    }
    
    return (
        <div className="Golf">
            <h3>
                Pick 5 Golf
            </h3>
            <div>
                <Link className="ContestLink" to="/accountsettings" key='link-accountsettings'>Account Settings</Link>
                <Link className="ContestLink" to="makepicks" key='link-golfmakepicks'>Make Picks</Link>
                <Link className="ContestLink" to="rules" key='link-golfrules'>Rules</Link>
            </div>
            {isLoading ? renderLoading() : renderGolfData()}
            <Footer/>
        </div>
    );
}

export default Golf;