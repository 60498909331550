import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    fetchP5, getNewDate, getSimpleDateString,
    postP5,
    useInterval
} from "../Utils";
import {
    InputLabel,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const inProgressRefreshInterval = 10;
const url = new URL(window.location.origin + "/api/v1/bbauction/auction_data");
const params = new URLSearchParams(window.location.search);
let year, groupId;
if (params.get('year')) {
    year = params.get('year'); 
}
else {
    year = new Date().getFullYear().toString();
}
url.searchParams.append('year', year);
if (params.get('group')) {
    groupId = params.get('group');
}
else {
    groupId = '1';
}
url.searchParams.append('group', groupId);
const fetchUrl = url.toString();
let remainingTeams;

function Auction(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [refreshIn, setRefreshIn] = useState(inProgressRefreshInterval);
    const [refreshInterval, setRefreshInterval] = useState(inProgressRefreshInterval);
    const [configData, setConfigData] = useState({});
    const [ownersData, setOwnersData] = useState([]);
    const [team1Events, setTeam1Events] = useState([]);
    const [team2Events, setTeam2Events] = useState([]);
    const [teams1Data, setTeams1Data] = useState([]);
    const [teams2Data, setTeams2Data] = useState([]);
    const [teams3Data, setTeams3Data] = useState([]);
    const [teams4Data, setTeams4Data] = useState([]);
    const [allTeamsData, setAllTeamsData] = useState([]);
    const [allTournamentTeams, setAllTournamentTeams] = useState([]);
    const [totalPot, setTotalPot] = useState(0);
    const [currentTeam, setCurrentTeam] = useState(null);
    const [currentOwnerName, setCurrentOwnerName] = useState("");
    const [currentPrice, setCurrentPrice] = useState(0);

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'bbAuctionAuction', props)
            .then(data => {
                const config = props.getState().bbAuctionAuction.data.config.data;
                setConfigData(config);
                setOwnersData(props.getState().bbAuctionAuction.data.owners.data);
                setTeam1Events(data.data.team1_events.data);
                setTeam2Events(data.data.team2_events.data);
                setTeams1Data(props.getState().bbAuctionAuction.data.teams1.data);
                setTeams2Data(props.getState().bbAuctionAuction.data.teams2.data);
                setTeams3Data(props.getState().bbAuctionAuction.data.teams3.data);
                setTeams4Data(props.getState().bbAuctionAuction.data.teams4.data);
                setAllTeamsData(props.getState().bbAuctionAuction.data.all_teams.data);
                
                const allTournamentTeamsData = []
                    .concat(props.getState().bbAuctionAuction.data.teams1.data)
                    .concat(props.getState().bbAuctionAuction.data.teams2.data)
                    .concat(props.getState().bbAuctionAuction.data.teams3.data)
                    .concat(props.getState().bbAuctionAuction.data.teams4.data);
                setAllTournamentTeams(allTournamentTeamsData);
                
                remainingTeams = props.getState().bbAuctionAuction.data.teams1.data.filter(function (team) {
                    return team.Owner == null;
                });
                remainingTeams = remainingTeams.concat(props.getState().bbAuctionAuction.data.teams2.data.filter(function (team) {
                    return team.Owner == null;
                }));
                remainingTeams = remainingTeams.concat(props.getState().bbAuctionAuction.data.teams3.data.filter(function (team) {
                    return team.Owner == null;
                }));
                remainingTeams = remainingTeams.concat(props.getState().bbAuctionAuction.data.teams4.data.filter(function (team) {
                    return team.Owner == null;
                }));

                if (config.current_team) {
                    const currentTeam = allTournamentTeamsData.filter((team) => team.Team_ID === config.current_team)[0];
                    if (currentTeam) {
                        setCurrentTeam(currentTeam);
                        setCurrentOwnerName(currentTeam.Owner);
                        setCurrentPrice(currentTeam.Price);
                    }
                }

                setTotalPot(props.getState().bbAuctionAuction.data.owners.data.reduce((acc, val) => {
                    acc += val.TotalPaid;
                    return acc;
                }, 0));
                if (config.drafting && !config.admin) {
                    setRefreshInterval(inProgressRefreshInterval);
                } else {
                    setRefreshInterval(0);
                }
            });
    };

    useInterval(() => {
        if (refreshInterval) {
            if (refreshIn === 0) {
                fetchData();
                setRefreshIn(refreshInterval);
            } else {
                setRefreshIn(refreshIn - 1);
            }
        }
    }, 1000);

    useEffect(() => {
        document.title = "Pick 5 NCAA Tournament Auction Auction";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            setIsLoading(false);
            fetchData();
        }
    }, []);

    function renderLoading() {
        return <div>Loading</div>;
    }
    
    function renderOwnersData() {
        return (
            <div className="BBAuctionAuctionOwnersContainer">
                <h2>Owners</h2>
                <TableContainer component={Paper} className="BBAuctionStatsTableContainer">
                    <Table size="small" className="BBAuctionDataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Owner</TableCell>
                                <TableCell>Total Teams</TableCell>
                                <TableCell>Total Paid</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ownersData.sort((a,b) => a.Name.localeCompare(b.Name)).map((owner) =>
                                <TableRow key={`ownersTableRow-${owner.Name}`}>
                                    <TableCell style={{color: owner.Color}}>{owner.Name}</TableCell>
                                    <TableCell>{owner.TotalTeams}</TableCell>
                                    <TableCell>{owner.TotalPaid}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="BBAuctionAuctionTotalPot">Total Pot: {totalPot}</div>
            </div>
        );
    }
    
    function handleCurrentOwnerChange(item) {
        setCurrentOwnerName(item.target.value);
    }
    
    function handleCurrentPriceChange(e) {
        setCurrentPrice(Number(e.target.value));
    }
    
    function handleSavePurchaseButtonClick() {
        const url = "/api/v1/bbauction/buy_team?owner=" + currentOwnerName + "&team_id=" + currentTeam.Team_ID + "&price=" + currentPrice + "&year=" + year + "&group_id=" + groupId;
        fetchP5(props.getState().userName, props.getState().password, url)
            .then(data => {
                if (data.data && data.data.result && data.data.result === 'success') {
                    fetchData();
                }
            });
    }
    
    function nextTeam(nextTeamId) {
        if (!nextTeamId) {
            const nextTeam = remainingTeams[Math.floor(Math.random() * remainingTeams.length)];
            setCurrentTeam(nextTeam);
            nextTeamId = nextTeam.Team_ID;
        }
        else {
            setCurrentTeam(allTournamentTeams.filter((t) => t.Team_ID === nextTeamId)[0]);
        }

        const newConfig = {...configData, current_team: nextTeamId};
        setConfigData(newConfig);
        updateConfig(newConfig);
    }
    
    function updateConfig(newConfig) {
        postP5(props.getState().userName, props.getState().password, '/api/v1/bbauction/update_config', newConfig)
            .then(response => response.json())
            .then(data => {
                fetchData();
            });
    }
    
    function renderAdminControls() {
        return (
            <div>
                <div className="BBAuctionAuctionAdminControlsContainer">
                    <h2>Auction Controls</h2>
                    <div className="BBAuctionAuctionAdminControls">
                        <Button size="large" onClick={() => nextTeam(0)}>Next Team</Button>
                        <InputLabel htmlFor="current-team-label">Team:</InputLabel>
                        <TextField id="current-team-label" disabled value={currentTeam ? currentTeam.Name : ''}/>
                        <InputLabel id="current-owner-label">Owner:</InputLabel>
                        <Select value={currentOwnerName} onChange={handleCurrentOwnerChange} size="small" labelId="current-owner-label">
                            <MenuItem value={null}/>
                            {ownersData.map((owner) =>
                                <MenuItem key={`currentOwnerItem-${owner.Name}`} value={owner.Name}>{owner.Name}</MenuItem>
                            )}
                        </Select>
                        <InputLabel htmlFor="current-price-label">Price:</InputLabel>
                        <TextField id="current-price-label" value={currentPrice} onChange={handleCurrentPriceChange} type="number"/>
                        <Button size="large" onClick={handleSavePurchaseButtonClick}>Save</Button>
                    </div>
                </div>
            </div>
        );
    }
    
    function regionRowClassName(team) {
        if (!currentTeam) {
            return '';
        }
        if (team.Team_ID === currentTeam.Team_ID) {
            return 'BBAuctionAuctionSelectedRow';
        }
        if (currentTeam.opp_team_id.split(',').indexOf(team.Team_ID.toString()) !== -1) {
            return 'BBAuctionAuctionOpponentRow';
        }
        return '';
    }

    function computeRecord(events, team_id) {
        let wins = 0, losses = 0;
        events.forEach(function(event) {
            if (event.status_id === 3) {
                if (event.team1_id === team_id) {
                    if (event.team1_score > event.team2_score) {
                        wins++;
                    }
                    else {
                        losses++;
                    }
                }
                else {
                    if (event.team1_score > event.team2_score) {
                        losses++;
                    } else {
                        wins++;
                    }
                }
            }
        });

        return "  (" + wins + ' - ' + losses + ")";
    }

    function eventRowClass(teamId, oppTeamId, eventTeamId) {
        return eventTeamId === teamId ? 'BBAuctionAuctionSelectedRow' : eventTeamId === oppTeamId ? 'BBAuctionAuctionOpponentRow' : '';
    }
    
    function renderTeamEvents(teamId, oppTeamId, events) {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            {events.map((event) =>
                                <TableRow key={`teamEvents-${teamId}-${event.id}`}>
                                    <TableCell className="BBAuctionAuctionTeamEventsTableContainer">
                                        <Table size="small" className="BBAuctionAuctionTeamEventsTable">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className="BBAuctionAuctionTeamEventsDateCell">{getSimpleDateString(getNewDate(event.date))}</TableCell>
                                                    <TableCell className={`BBAuctionAuctionTeamEventsNameCell ${eventRowClass(teamId, oppTeamId, event.team1_id)}`}>{event.team1_name}</TableCell>
                                                    <TableCell className={`${eventRowClass(teamId, oppTeamId, event.team1_id)}`}>{event.team1_score}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="BBAuctionAuctionTeamEventsDateCell"/>
                                                    <TableCell className={`BBAuctionAuctionTeamEventsNameCell ${eventRowClass(teamId, oppTeamId, event.team2_id)}`}>{event.team2_name}</TableCell>
                                                    <TableCell className={`${eventRowClass(teamId, oppTeamId, event.team2_id)}`}>{event.team2_score}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderAuctionData() {
        return (
            <div>
                <div className="BBAuctionAuctionLeftContainer">
                    <div className="BBAuctionAuctionTopContainer">
                        {renderOwnersData()}
                        {configData.admin ? renderAdminControls() : ''}
                    </div>
                    <div className="BBAuctionAuctionBottomContainer">
                        {[{r:"SOUTH", t:teams1Data}, {r:"EAST", t:teams2Data}, {r:"WEST", t:teams3Data}, {r:"MIDWEST", t:teams4Data}].map((region) =>
                            <div className="BBAuctionAuctionRegionContainer" key={`regionContainer-${region.r}`}>
                                <h2>{region.r}</h2>
                                <TableContainer component={Paper} className="BBAuctionStatsTableContainer">
                                    <Table size="small" className="BBAuctionAuctionRegionTable">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Seed</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Owner</TableCell>
                                                <TableCell>Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {region.t.sort((a,b) => a.Seed - b.Seed).map((team) =>
                                                <TableRow key={`region${region}TableRow-${team.Name}`} onClick={() => nextTeam(team.Team_ID)} className={regionRowClassName(team)}>
                                                    <TableCell>{team.Seed}</TableCell>
                                                    <TableCell>{team.Name}</TableCell>
                                                    <TableCell>{team.Owner}</TableCell>
                                                    <TableCell>{team.Price}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                </div>
                <div className="BBAuctionAuctionTeamRecordsContainer">
                    {currentTeam ?
                        <div className="BBAuctionAuctionRegionContainer">
                            <div className="BBAuctionAuctionTeamDetailsContainer">
                                <img src={`/images/teamlogos/ncaa/100/${currentTeam.Team_ID}.png`} alt="team1"/>
                                <h4>{currentTeam.Name} {computeRecord(team1Events, currentTeam.Team_ID)}</h4>
                                {renderTeamEvents(currentTeam.Team_ID, Number(currentTeam.opp_team_id), team1Events)}
                            </div>
                            {
                                currentTeam.opp_team_id.indexOf(',') > -1 ? '' :
                                    <div className="BBAuctionAuctionTeamDetailsContainer">
                                        <img src={`/images/teamlogos/ncaa/100/${currentTeam.opp_team_id}.png`} alt="team2"/>
                                        <h4>{allTeamsData.filter((team) => team.ID === Number(currentTeam.opp_team_id))[0].Name} {computeRecord(team2Events, currentTeam.opp_team_id)}</h4>
                                        {renderTeamEvents(currentTeam.Team_ID, Number(currentTeam.opp_team_id), team2Events)}
                                    </div>
                            }
                        </div>
                        : null}
                </div>
            </div>
        );
    }
    
    return (
        <div className="BBAuctionAuction">
            <h1>NCAA Auction</h1>
            {isLoading ? renderLoading() : renderAuctionData()}
        </div>
    );
}

export default Auction;